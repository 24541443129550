<template>
  <div class='container'>
    <van-empty :image="require('@/assets/project/professionReport.png')" description="暂未获取报告">
      <template slot="description">
        <div style="text-align: center;color: #333;line-height: 6.4vw;"> 
          <p>专家分析报告将在测评结束时间后的两周内出具,届时您将收到短信通知</p>
          <p>请耐心等待~ 如需帮助可拨打:</p>
          <p>020-31703170</p>
          <div class="btns">
            <button @click="toPage">查看报告案例</button>
            <button @click="$router.go(-1)">返回</button>
          </div>
        </div>
      </template>
    </van-empty>
    
  </div>
</template>

<script>
export default {
  data(){
    return{
 
    }
  },
  created(){
 
  },
  methods:{
    toPage(){
      window.open('https://img.uelink.com.cn/upload/qsn_school_pc_web/专家报告样例.pdf')
    }
  }
}
</script>
<style lang='less' scoped>
.container{
  padding:  4vw 0;
  min-height: 100%;
  .btns{
    margin-top: 4vw;
    button{
      padding: 0 2.6667vw;
      font-size: 4vw;
      height: 9.6vw;
      min-width: 18.6667vw;
      line-height: 9.6vw;
      border-radius: 1.3333vw;
      background-color: #fff;
      border: .2667vw solid #cdd3fd;
      &:first-child{
        color: #fff;
        background-color: #7a8af9;
        margin-right: 4vw;
      }
    }
  }
}
</style>